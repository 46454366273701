import { PhoneFilterValues } from '@capturi/api-filters'
import {
  SentimentScore,
  SentimentSpeaker,
  SpeakerSentimentResponseModel,
  sentimentAPI,
} from '@capturi/api-sentiment'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ColorLabel } from '@capturi/charts'
import { useCurrentUser } from '@capturi/core'
import {
  Segment,
  toFilterSearchParams,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import {
  Caption,
  ContentPlaceholder,
  Highlight,
  List,
  ListItem,
  PlaySnippetsButton,
  SectionHeading,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Grid,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import qs from 'query-string'
import React, { useContext, useMemo, useState } from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

import { useStore } from '@tanstack/react-store'
import TeamFilterRequiredMessage from '../../shared/components/TeamFilterRequiredMessage'
import { pctFormat } from '../../shared/utils'
import SpeakerContext from '../contexts/SpeakerContext'
import { sentimentTimeDataTabIndex } from '../data/state'
import { Event, logEvent } from '../events'
import SentimentTab from './SentimentTab'
import TeamHits from './TeamHits'
import TimeDataContainer from './TimeDataContainer'
import UserHits from './UserHits'

const QUERY_KEY = 'score'
const scores: SentimentScore[] = ['High', 'Medium', 'Low']
const defaultIndex = 1

function useScoreTabsState(): [
  number,
  React.Dispatch<React.SetStateAction<number>>,
] {
  const state = useState(() => {
    // read query param initially and set tabs state
    const parsedQuery = qs.parse(window.location.search)
    let scoreParam = parsedQuery[QUERY_KEY]
    if (Array.isArray(scoreParam)) {
      scoreParam = scoreParam[0]
    }
    if (scoreParam == null) {
      return defaultIndex
    }
    const tabsIndex = scores.indexOf(scoreParam as SentimentScore)
    return tabsIndex === -1 ? defaultIndex : tabsIndex
  })

  // Sync changes to url query params
  useSyncSearchParam(QUERY_KEY, scores[state[0]])
  return state
}
function calcPct(
  input: number,
  data: SpeakerSentimentResponseModel | null,
): number {
  if (data === null) return 0
  const totalConversations =
    data.conversationsWithHighScore +
    data.conversationsWithMediumScore +
    data.conversationsWithLowScore

  if (totalConversations === 0) return 0
  return input / totalConversations
}

const localizeScore = (score: SentimentScore): string =>
  select(score, {
    Low: 'low',
    Medium: 'medium',
    High: 'high',
    other: 'unknown',
  })

const dataBasisMsgs: {
  [key in Exclude<SentimentSpeaker, 'Combined'>]: (
    score: SentimentScore,
    organizationType: string,
    hits?: number,
    total?: number,
  ) => string
} = {
  User: (score, _organizationType, hits = 0, total = 0): string =>
    t`${hits} out of ${total} conversations are categorized with ${localizeScore(
      score,
    )} sentiment score for the employee`,
  Other: (score, organizationType, hits = 0, total = 0): string =>
    select(organizationType, {
      public: `${hits} out of ${total} conversations are categorized with ${localizeScore(
        score,
      )} sentiment score for the citizen`,
      other: `${hits} out of ${total} conversations are categorized with ${localizeScore(
        score,
      )} sentiment score for the customer`,
    }),
}

function renderTabText(score: SentimentScore): React.ReactNode {
  return (
    <Trans>
      Conversations with{' '}
      <Highlight fontWeight="bold">{localizeScore(score)}</Highlight> score
    </Trans>
  )
}

export type SentimentScoreHits = {
  total: number
  hitRate: number
  hits: number
}

const DataTabs: React.FC = () => {
  const user = useCurrentUser()
  const { i18n } = useLingui()
  const openConversationsDrawer = useConversationsDrawer()
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const { organizationType } = useOrganization()
  const { periodDef } = useFilterPeriodContext()

  const speaker = useContext(SpeakerContext)
  const {
    segments,
    isLoading,
    error: segmentError,
  } = useFetchSegments<SpeakerSentimentResponseModel>(() =>
    sentimentAPI.getSpeakerSentiment(speaker),
  )

  const [tabsIndex, setTabsIndex] = useScoreTabsState()
  const dataTabsIndex = useStore(sentimentTimeDataTabIndex)

  const dataTabs = useMemo<
    {
      score: SentimentScore
      title: React.ReactNode
      values: Segment<SentimentScoreHits>[]
    }[]
  >(() => {
    return [
      {
        score: 'High',
        title: renderTabText('High'),
        values: segments.map((s) => ({
          color: s.color,
          label: s.label,
          data: {
            total: s.data?.conversations ?? 0,
            hitRate: calcPct(s.data?.conversationsWithHighScore ?? 0, s.data),
            hits: s.data?.conversationsWithHighScore ?? 0,
          },
        })),
      },
      {
        score: 'Medium',
        title: renderTabText('Medium'),
        values: segments.map((s) => ({
          color: s.color,
          label: s.label,
          data: {
            total: s.data?.conversations ?? 0,
            hitRate: calcPct(s.data?.conversationsWithMediumScore ?? 0, s.data),
            hits: s.data?.conversationsWithMediumScore ?? 0,
          },
        })),
      },
      {
        score: 'Low',
        title: renderTabText('Low'),
        values: segments.map((s) => ({
          color: s.color,
          label: s.label,
          data: {
            total: s.data?.conversations ?? 0,
            hitRate: calcPct(s.data?.conversationsWithLowScore ?? 0, s.data),
            hits: s.data?.conversationsWithLowScore ?? 0,
          },
        })),
      },
    ]
  }, [segments])

  const onViewConversations = (
    sentimentScore: SentimentScore,
    state: PhoneFilterValues,
  ): void => {
    logEvent(Event.ViewSentimentScoreConversations, speaker, sentimentScore)
    openConversationsDrawer({
      url: 'conversations/list?api-version=3.3&excludeDeleted=false',
      getFilterRequestModel: () =>
        toFilterSearchParams(
          {
            ...state,
            sentiment: {
              score: sentimentScore,
              speaker,
            },
          },
          periodDef,
        ),
      initialTab: 'sentiment',
    })
  }

  const onTabClicked = (score: SentimentScore): void => {
    logEvent(Event.ScoreTabClicked, speaker, score)
  }

  if (user.isTeamLead && segmentError?.statusCode === 403)
    return <TeamFilterRequiredMessage />

  if (segmentError && segmentError.statusCode === 403)
    return <NoAccessibleTrackers />

  if (segmentError) return <DefaultFallbackComponent error={segmentError} />

  const isEmpty = !(
    isLoading ||
    segments.some((segment) => segment.data && segment.data.conversations !== 0)
  )

  if (isEmpty) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>No measured sentiment</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Try extending the period in the top right corner or make your
            filtration wider
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Tabs
      index={tabsIndex}
      onChange={setTabsIndex}
      orientation="vertical"
      isLazy
      display="flex"
      variant="enclosed-colored-vertical"
    >
      <TabList>
        {dataTabs.map((props) => (
          <SentimentTab
            key={props.score}
            label={props.title}
            values={props.values.map((v) => ({
              ...v,
              data: v.data?.hitRate || 0,
            }))}
            isLoading={isLoading}
            onClick={() => onTabClicked(props.score)}
          />
        ))}
      </TabList>
      {/* 
        `width: 0` is important in order for recharts' ResponsiveContainer to properly
        react to resize events. If removed, then the ResponsiveContainer will not shrink
        properly (it will grow however).
        This is due to a sublety of flexbox where the contents (ResponsiveContainer) of a flex child (TabPanels)
        will affect the flex child width.
        See: https://stackoverflow.com/questions/7985021/css-flexbox-issue-why-is-the-width-of-my-flexchildren-affected-by-their-content        
      */}
      <TabPanels flex="1" sx={{ width: 0 }}>
        {dataTabs.map((props) => (
          <TabPanel key={props.score} pl={8} pr={0}>
            <Flex align="center">
              <SectionHeading m={0} textTransform="capitalize">
                {props.title}
              </SectionHeading>
            </Flex>
            <List disablePadding mb={4}>
              {props.values.map((s, index) => (
                <ListItem
                  key={s.label}
                  hasDivider={props.values.length > 1}
                  disableGutters
                >
                  <Grid
                    flex={1}
                    templateColumns="auto 1fr"
                    columnGap={2}
                    alignItems="center"
                  >
                    <Box gridRow={1}>
                      <ColorLabel color={s.color} />
                    </Box>
                    <Box>
                      <Highlight>
                        <Skeleton
                          isLoaded={!isLoading}
                          fadeDuration={1}
                          width={isLoading ? '50px' : undefined}
                        >
                          {i18n.number(s.data?.hitRate ?? 0, pctFormat)}
                        </Skeleton>
                      </Highlight>
                    </Box>

                    <Skeleton
                      isLoaded={!isLoading}
                      fadeDuration={1}
                      width="fit-content"
                      gridRow={2}
                      gridColumn={2}
                    >
                      <Caption color="textMuted">
                        {dataBasisMsgs[speaker](
                          props.score,
                          organizationType,
                          s.data?.hits,
                          s.data?.total,
                        )}
                      </Caption>
                    </Skeleton>

                    <Box gridRow="1 / 3">
                      <PlaySnippetsButton
                        label={t`View conversations`}
                        onClick={() =>
                          onViewConversations(props.score, states[index].values)
                        }
                        isDisabled={isLoading || s.data?.hits === 0}
                      />
                    </Box>
                  </Grid>
                </ListItem>
              ))}
            </List>
            <TimeDataContainer
              sentimentScore={props.score}
              segmentAverages={props.values}
            />

            <Tabs
              index={dataTabsIndex}
              onChange={(index) =>
                sentimentTimeDataTabIndex.setState(() => index)
              }
              isLazy
              mt={4}
            >
              <TabList>
                <Tab key="employees">{t`Employees`}</Tab>
                <Tab key="teams">{t`Teams`}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key="employees" px={0}>
                  <UserHits sentimentScore={props.score} />
                </TabPanel>
                <TabPanel key="teams" px={0}>
                  <TeamHits sentimentScore={props.score} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default DataTabs
